<script setup>
import { onMounted, watch, reactive, ref, computed } from "vue";
import { useUserStore } from "../../stores/user";
// @ts-ignore
import { useRouter } from "vue-router";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { shortDateWithTimeTz } from "../../utils/dateUtils";
import {
  ticketStatusColor,
  ticketPriorityColor,
  ticketCategoryColor,
} from "../../utils/supportTicketUtils";

// @ts-ignore
import { useRoute } from "vue-router";
import useSupportTickets from "../../hooks/useSupportTickets";
import { useDisplay } from "vuetify";
import gravatarUrl from "gravatar-url";
import debounce from "lodash/debounce";
import LoadingProgess from "../../components/LoadingProgress.vue";
import useTenants from "../../hooks/useTenants";

// Constants for localStorage keys
const LOCAL_STORAGE_TICKET_FILTERS_KEY = "ticketFilters";
const LOCAL_STORAGE_ASSIGNED_USERS_KEY = "selectedAssigneess";
const SUPPORT_TICKET_BASE_URL = "/support-tickets";

const {
  updatePage,
  supportTicketsLoading,
  supportTickets,
  fetchSupportTickets,
  ticketStatuses,
  ticketPriorities,
  ticketCategories,
  fetchSupportTicketUsers,
  getSupportTicketsCountByStatus,
  supportTicketsCountByStatus,
} = useSupportTickets();
const { fetchTenants, tenants } = useTenants();
const { mdAndUp } = useDisplay();
const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const showFilters = ref(false);
const refetchData = ref(false);
const selectedTenants = ref([]);

const selectedStatuses = ref(ticketStatuses.map((p) => p.value));
const selectedPriorities = ref(ticketPriorities.map((p) => p.value));
const selectedCategories = ref(ticketCategories.map((p) => p.value));
const queryFiltersRaw = route.query?.filters;
const selectedAssignees = ref(null);
const contentBody = ref("");
const createdBy = ref("");
const assigneeUsersList = ref([]);
const createdByUsersList = ref([]);
const defaultState = {
  page: 1,
  searchText: null,
  filters: [],
  sort: "id",
};
const ticketUsersState = reactive({
  page: 1,
  searchText: null,
  filters: [],
  sort: "id",
});

if (queryFiltersRaw) {
  let defaultStateCopy = { ...defaultState };
  defaultStateCopy.filters = JSON.parse(queryFiltersRaw);
  localStorage.setItem(
    LOCAL_STORAGE_TICKET_FILTERS_KEY,
    JSON.stringify(defaultStateCopy)
  );
  router.replace({ query: undefined });
}

const savedTicketFilters = localStorage.getItem(LOCAL_STORAGE_TICKET_FILTERS_KEY);
const localState = reactive(
  savedTicketFilters ? JSON.parse(savedTicketFilters) : defaultState
);

watch(route, (to) => {
  handleUrlChange(to);
  refetchData.value = true;
});

const clearAllFilters = () => {
  let filtersToReset = localState.filters.filter(
    (f) => f.column === "title" && f.condition === "like"
  );
  localState.filters = filtersToReset;
  selectedStatuses.value = Array.isArray(selectedStatuses.value) ? [] : "";
  selectedAssignees.value = [];
  selectedTenants.value = [];
  selectedCategories.value = [];
  selectedPriorities.value = [];
  contentBody.value = "";
  createdBy.value = "";
  debouncedFetchSupportTickets(localState);
};

onMounted(async () => {
  setPageTitle("Manage Support Tickets");
  await getSupportTicketsCountByStatus();

  // Update selectedStatuses based on loaded state
  if (queryFiltersRaw) {
    selectedStatuses.value =
      localState.filters
        .filter((f) => f.column === "status" && f.condition === "eq")
        .flatMap((f) => f.value) || "";
  } else {
    selectedStatuses.value =
      localState.filters
        .filter((f) => f.column === "status" && f.condition === "in")
        .flatMap((f) => f.value) || [];
  }

  selectedTenants.value =
    localState.filters
      .filter((f) => f.column === "tenant_id" && f.condition === "in")
      .flatMap((f) => f.value) || [];
  selectedCategories.value =
    localState.filters
      .filter((f) => f.column === "category" && f.condition === "in")
      .flatMap((f) => f.value) || [];
  selectedPriorities.value =
    localState.filters
      .filter((f) => f.column === "priority" && f.condition === "in")
      .flatMap((f) => f.value) || [];

  selectedAssignees.value =
    localState.filters
      .filter((f) => f.column === "assignments.assigned_to_id" && f.condition === "in")
      .flatMap((f) => f.value) || [];

  const filterArray = localState.filters.filter(
    (f) => f.column === "content_text" && f.condition === "like_scored"
  );

  if (filterArray != undefined && filterArray.length > 0) {
    contentBody.value = filterArray[0].value || "";
  }

  const filterByCreator = localState.filters.filter(
    (f) => f.column === "created_by_id" && f.condition === "in"
  );

  if (filterByCreator != undefined && filterByCreator.length > 0) {
    createdBy.value = filterByCreator[0].value || "";
  }

  const storedShowFilters = localState.showFilters;
  showFilters.value = storedShowFilters != null ? JSON.parse(storedShowFilters) : false;
  const employeeParams = {
    sort: "-user.name",
    page_size: 1000,
  };
  if (userStore.isSupportTicketAdmin) {
    assigneeUsersList.value = await fetchSupportTicketUsers(employeeParams);
    createdByUsersList.value = await fetchSupportTicketUsers(employeeParams);
  }
  if (userStore.hasSupportTicketRole("superadmin") && userStore.user.tenant_id == 1) {
    await fetchTenants();
  }
  handleUrlChange(route);
  await debouncedFetchSupportTickets(localState || defaultState);
});

const debouncedFetchSupportTickets = debounce(async (localState) => {
  await fetchSupportTickets(localState || defaultState);
}, 500);

watch(
  localState,
  () => {
    localStorage.setItem(LOCAL_STORAGE_TICKET_FILTERS_KEY, JSON.stringify(localState));
    if (refetchData.value) {
      debouncedFetchSupportTickets(localState);
      refetchData.value = false;
    }
  },
  { deep: true }
);

watch(
  selectedAssignees,
  (newVal) => {
    localStorage.setItem(LOCAL_STORAGE_ASSIGNED_USERS_KEY, JSON.stringify(newVal));
    localState.filters = localState.filters.filter(
      (filter) => filter.column !== "assignments.assigned_to_id"
    );
    if (selectedAssignees.value && selectedAssignees.value.length > 0) {
      localState.filters.push({
        condition: "in",
        column: "assignments.assigned_to_id",
        value: selectedAssignees.value,
      });
    } else {
      localState.filters = localState.filters.filter(
        (filter) => filter.column !== "assignments.assigned_to_id"
      );
    }
    localState.page = 1;
  },
  { deep: true }
);

watch(contentBody, (newVal) => {
  localState.filters = localState.filters.filter(
    (filter) => filter.column !== "content_text"
  );

  if (contentBody.value != undefined && contentBody.value !== "") {
    localState.filters.push({
      condition: "like_scored",
      column: "content_text",
      value: contentBody.value,
    });
  } else {
    localState.filters = localState.filters.filter(
      (filter) => filter.column !== "content_text"
    );
  }
  localState.page = 1;
});

watch(createdBy, (newVal) => {
  localState.filters = localState.filters.filter(
    (filter) => filter.column !== "created_by_id"
  );

  if (createdBy.value != undefined && createdBy.value !== "") {
    localState.filters.push({
      condition: "in",
      column: "created_by_id",
      value: createdBy.value,
    });
  } else {
    localState.filters = localState.filters.filter(
      (filter) => filter.column !== "created_by_id"
    );
  }
  localState.page = 1;
});

watch(
  () => localState.searchText,
  (newVal) => {
    localState.filters = localState.filters.filter((filter) => filter.column !== "title");

    localState.filters.push({
      condition: "like",
      column: "title",
      value: newVal,
    });
    localState.page = 1;
    refetchData.value = true;
  },
  { deep: true, immediate: false }
);

watch(
  selectedStatuses,
  () => {
    localState.filters = localState.filters.filter(
      (filter) => filter.column !== "status"
    );
    if (selectedStatuses.value.length > 0) {
      localState.filters.push({
        condition: "in",
        column: "status",
        value: selectedStatuses.value,
      });
    }
    localState.page = 1;
  },
  { deep: true }
);

watch(
  selectedPriorities,
  () => {
    localState.filters = localState.filters.filter(
      (filter) => filter.column !== "priority"
    );
    if (selectedPriorities.value.length > 0) {
      localState.filters.push({
        condition: "in",
        column: "priority",
        value: selectedPriorities.value,
      });
    }
    localState.page = 1;
  },
  { deep: true }
);

watch(
  selectedCategories,
  () => {
    localState.filters = localState.filters.filter(
      (filter) => filter.column !== "category"
    );
    if (selectedCategories.value.length > 0) {
      localState.filters.push({
        condition: "in",
        column: "category",
        value: selectedCategories.value,
      });
    }
    localState.page = 1;
  },
  { deep: true }
);

const newTicketClass = computed(() => {
  return mdAndUp.value ? "mx-4 d-flex justify-end" : "mx-4 d-flex justify-center";
});

watch(
  selectedTenants,
  (newVal) => {
    localStorage.setItem(LOCAL_STORAGE_ASSIGNED_USERS_KEY, JSON.stringify(newVal));
    localState.filters = localState.filters.filter(
      (filter) => filter.column !== "tenant_id"
    );
    if (selectedTenants.value && selectedTenants.value.length > 0) {
      localState.filters.push({
        condition: "in",
        column: "tenant_id",
        value: selectedTenants.value,
      });
    } else {
      localState.filters = localState.filters.filter(
        (filter) => filter.column !== "tenant_id"
      );
    }
    localState.page = 1;
  },
  { deep: true }
);

const handleUrlChange = (url) => {
  if (url !== undefined) {
    if (url.fullPath === SUPPORT_TICKET_BASE_URL) {
      localState.page = 1;
    }

    const { page } = url.query;

    if (page) {
      localState.page = Number(page);
    }
  }
};

const applyTenantFilters = async () => {
  localState.filters = localState.filters.filter(
    (filter) => filter.column !== "tenant_id"
  );
  if (selectedTenants.value && selectedTenants.value.length > 0) {
    localState.filters.push({
      condition: "in",
      column: "tenant_id",
      value: selectedTenants.value,
    });
  }
  localState.page = 1;
};

const handlePageSizeChange = (pageSize) => {
  localState.page_size = pageSize;
  refetchData.value = true;
};

const handlePageChange = () => {
  updatePage(localState.page);
  refetchData.value = true;
};

watch(showFilters, () => {
  localState.showFilters = showFilters.value;
});

async function searchUsers(search, type) {
  if (search === "" || search === undefined) {
    return;
  }

  ticketUsersState.filters = ticketUsersState.filters.filter(
    (filter) => filter.column !== "name"
  );
  ticketUsersState.filters.push({
    condition: "like",
    column: "name",
    value: search,
  });
  debouncedSupportTicketUsers(type);
}

const debouncedSupportTicketUsers = debounce(async (type) => {
  if (type === "assignee") {
    assigneeUsersList.value = await fetchSupportTicketUsers(ticketUsersState);
  }
  if (type === "created_by") {
    createdByUsersList.value = await fetchSupportTicketUsers(ticketUsersState);
  }
}, 500);

const disableClearAll = () => {
  return localState.filters.filter((f) => f.column !== "title")?.length === 0;
};

const headers = [
  { title: "#", value: "id", sortable: true },
  { title: "TITLE", value: "title", sortable: true },
  { title: "STATUS", value: "status", sortable: true },
  { title: "PRIORITY", value: "priority", sortable: true },
  { title: "CATEGORY", value: "category", sortable: true },
  { title: "ASSIGNED TO", value: "assignments" },
  { title: "CREATED BY", value: "created_by.name", sortable: true, filterable: true },
  { title: "CREATED AT", value: "created_at", sortable: true },
];

function convertToTitleCase(str) {
  return str
    .split("_") // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join the words with a space
}

function handleStatusChipSelection(statusSelected) {
  selectedStatuses.value = [];
  if (statusSelected != "total") {
    selectedStatuses.value.push(statusSelected);
  }
  debouncedFetchSupportTickets(localState);
}

const handleRowClick = (event, row) => {
  router.push(`/support-tickets/${row.item.id}`);
};

const handleColClick = (item) => {
  router.push(`/support-tickets/${item.id}`);
};
</script>

<template>
  <div v-if="userStore.user">
    <!-- Title and New Ticket Button -->
    <v-row class="mb-1">
      <v-col>
        <v-card-title class="d-flex justify-space-between flex-wrap lg:flex-nowrap title">
          <span>{{ pageTitle }}</span>
        </v-card-title>
      </v-col>
      <v-col cols="12" md="2" :class="newTicketClass">
        <v-btn color="primary" to="/support-tickets/new">New Ticket</v-btn>
      </v-col>
    </v-row>

    <!-- Support ticket counts -->
    <div v-if="supportTicketsCountByStatus">
      <div class="text-center mb-4">
        <v-chip
          v-for="(value, key) in supportTicketsCountByStatus"
          :key="key"
          class="ma-2"
          :color="ticketStatusColor(key)"
          :size="key === 'open' ? 'x-large' : 'default'"
          @click="handleStatusChipSelection(key)"
        >
          {{ convertToTitleCase(key) }}({{ value }})
        </v-chip>
      </div>
    </div>

    <div class="d-flex ml-4 container">
      <v-card v-if="showFilters" class="filter-card pl-2 pr-2 mr-2">
        <v-select
          class="mt-3"
          label="Filter by Status"
          v-model="selectedStatuses"
          :items="ticketStatuses"
          item-text="title"
          item-value="value"
          multiple
          closable-chips
          chips
        />

        <v-select
          class="mt-2"
          label="Filter by Priority"
          v-model="selectedPriorities"
          :items="ticketPriorities"
          item-text="title"
          item-value="value"
          multiple
          closable-chips
          chips
        />

        <v-select
          class="mt-2"
          label="Filter by Category"
          v-model="selectedCategories"
          :items="ticketCategories"
          item-text="title"
          item-value="value"
          multiple
          closable-chips
          chips
        />

        <v-select
          class="mt-2"
          v-if="
            userStore.user.permissions.support_ticket_role == 'superadmin' &&
            userStore.user.tenant_id == 1
          "
          label="Filter by Tenant"
          v-model="selectedTenants"
          :items="tenants?.data || []"
          item-title="name"
          item-value="id"
          multiple
          chips
          closable-chips
          @change="applyTenantFilters"
        />

        <v-autocomplete
          class="mt-2"
          label="Filter by Assignee"
          v-model="selectedAssignees"
          @update:search="(query) => searchUsers(query, 'assignee')"
          :items="assigneeUsersList?.data || []"
          item-title="name"
          item-value="id"
          chips
          closable-chips
          multiple
        />

        <v-text-field class="mt-2" label="Filter by Content Body" v-model="contentBody" />

        <!-- <v-text-field
          class="mt-2"
          label="Filter by Created By"
          v-model="createdBy"
        /> -->

        <v-autocomplete
          class="mt-2"
          label="Filter by Created By"
          @update:search="(query) => searchUsers(query, 'created_by')"
          v-model="createdBy"
          :items="createdByUsersList?.data || []"
          item-title="name"
          item-value="id"
        />

        <div class="actions d-flex justify-end">
          <v-btn
            :disabled="disableClearAll()"
            color="#f44336"
            append-icon="mdi-close-circle"
            class="mr-4 mb-3 mt-0 clear"
            @click="clearAllFilters"
            >Clear All</v-btn
          >
          <v-btn
            color="primary"
            @click="debouncedFetchSupportTickets(localState)"
            class="mr-4 mb-3 mt-0"
            >Apply</v-btn
          >
        </div>
      </v-card>

      <!-- Support Tickets Table -->
      <v-row class="ml-0 table-content">
        <v-col class="ml-0 pl-0">
          <v-card flat class="mx-4 ml-0">
            <div class="d-flex align-center">
              <v-btn
                color="blue"
                size="small"
                variant="outlined"
                @click="showFilters = !showFilters"
                class="filter-btn ml-2 mt-1 mr-2"
                :append-icon="showFilters ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                >{{ showFilters ? "Hide Filters" : "Show Filters" }}</v-btn
              >
              <!-- Search -->
              <v-row class="pb-2 px-2 mt-0">
                <v-col class="d-flex justify-end align-center">
                  <div class="d-flex align-center w-100">
                    <v-text-field
                      v-model="localState.searchText"
                      variant="outlined"
                      density="compact"
                      prepend-inner-icon="mdi-text-box-search-outline"
                      label="Search by title"
                      color="indigo-lighten-2"
                      class="w-100"
                      flat
                      hide-details
                    ></v-text-field>
                    <v-menu>
                      <template v-slot:activator="{ props }">
                        <v-btn color="primary" v-bind="props" variant="plain">
                          Page size: {{ localState.page_size || 20 }}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in [10, 25, 50, 100]"
                          :key="index"
                          :value="index"
                          @click="handlePageSizeChange(item)"
                        >
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div v-if="supportTicketsLoading">
              <div colspan="8" class="text-center">
                <v-progress-circular
                  size="30"
                  indeterminate
                  color="blue-darken-2"
                ></v-progress-circular>
              </div>
            </div>

            <v-data-table
              v-if="
                !supportTicketsLoading && supportTickets && supportTickets.data.length > 0
              "
              :headers="headers"
              :items="supportTickets.data"
              :items-per-page="localState.page_size"
              class="elevation-1"
              item-value="id"
              hide-default-footer
              @dblclick:row="handleRowClick"
            >
              <template v-slot:[`item.id`]="{ item }">
                <td @click="handleColClick(item)">
                  {{ item.id }}
                </td>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="ticketStatusColor(item.status)" size="small">
                  {{ item.status }}
                </v-chip>
              </template>

              <template v-slot:[`item.priority`]="{ item }">
                <v-chip :color="ticketPriorityColor(item.priority)" size="small">
                  {{ item.priority }}
                </v-chip>
              </template>

              <template v-slot:[`item.category`]="{ item }">
                <v-chip :color="ticketCategoryColor(item.category)" size="small">
                  {{ item.category }}
                </v-chip>
              </template>

              <template v-slot:[`item.assignments`]="{ item }">
                <div class="d-flex align-center">
                  <div
                    v-for="(assignment, index) in item.assignments"
                    :key="assignment.assigned_to.id"
                    class="avatar-stack"
                    @click="router.push(`/users/${assignment.assigned_to.id}`)"
                  >
                    <v-avatar
                      :image="
                        gravatarUrl(assignment.assigned_to.email, {
                          size: 32,
                          default: 'retro',
                        })
                      "
                      size="24"
                      color="surface-variant"
                      class="stacked-avatar"
                      :class="{ 'ml-n3': index !== 0 }"
                    >
                    </v-avatar>
                    <v-tooltip activator="parent" location="left">
                      {{ assignment.assigned_to.name }}
                    </v-tooltip>
                  </div>
                </div>
              </template>

              <template v-slot:[`item.created_at`]="{ item }">
                {{ shortDateWithTimeTz(item.created_at) }}
              </template>
            </v-data-table>
            <LoadingProgess v-if="!supportTickets" />

            <div
              class="text-center mt-4"
              v-if="supportTickets && supportTickets.total_pages > 1"
            >
              <v-pagination
                active-color="primary"
                color="grey-darken-1"
                v-model="localState.page"
                :length="supportTickets.total_pages"
                @update:modelValue="handlePageChange"
                rounded="circle"
              ></v-pagination>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style>
.v-field,
.v-list-item-title {
  font-size: 0.875rem !important;
}
</style>

<style scoped>
@media (max-width: 768px) {
  .title {
    justify-content: center !important;
  }

  .v-card .filter-card {
    width: 92%;
    margin-left: -11px;
    margin-top: 10px;
  }

  .container .table-content {
    width: 100%;
    margin-left: 5px;
  }

  .actions {
    flex-direction: column;
  }

  .v-btn {
    font-size: 12px;
    padding: 5px;
  }

  .container {
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
}

.filter-card >>> .v-label {
  font-size: 14px !important;
  margin-top: -7px;
}

.filter-card {
  height: 100%;
  width: 30%;
}

.table-content {
  width: 70%;
}

.avatar-stack {
  display: inline-block;
}

.stacked-avatar {
  box-shadow: 0 0 0 2px white;
  /* Adjust color to match your background */
  z-index: 1;
}

.stacked-avatar.ml-n3 {
  margin-left: -10px;
  /* Adjust the pixel value to increase or decrease the overlap */
  z-index: 0;
}
</style>
